import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "assets/am-charts-5/_css/amcharts5-export.css";
import "assets/am-charts-5/_css/amcharts5-export.min.css";
import moment from "moment";
import * as sheetJs from "xlsx";

function ShowParetoRejectMachStopsChart(props) {
  // Define data
  let chartData = props.chartData;
  let formData = props.chartForm;

  let chartId = props.id;

  console.log("ShowParetoRejectMachStopsChart chartData:", chartData);
  console.log("ShowParetoRejectMachStopsChart formData:", formData);

  /** Map data provided by API (as done by Dot Net program ). This can be changed later to work best for React JS */
  const dataSet = chartData.map((object) => {
    return {
      //machineId: object.machine_id, /** To check: machine_id does not exist in the values passed.  so, commented it.  Velmani */
      rejectRatio: object.reject_ratio,
      sortOrder: object.sort_order,
      name: object.machine_name,
      data_type: object.data_type,
      cumulativePercentage: object.pareto,
    };
  });

  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
    dataSets: {
      byCalendarDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      }),
      byProductionDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      })
    },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat("en-US", {
      year: "2-digit" /*** To check: in chart-form-controls-mixin.js, it is  "2-digit" ***/,
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    startingDateTime: new Date(formData.p_param.p_start_datetime),
    endingDateTime: new Date(formData.p_param.p_end_datetime),
  };

  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /***
   * Step 1 Calculate Avg values
   *         return new HourlyMachineEfficiency
   */

  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   *
   */
  console.log(dates.startingDateTime);
  console.log(dates.endingDateTime);
  let timeDiff =
    dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, "") -
    dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, "");
  console.log("timeDiff", timeDiff);

  const startingDateTimeFmt = dates.dateTimeFormat
    .format(dates.startingDateTime)
    .replace(/,/g, "");
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat
    .format(dates.endingDateTime)
    .replace(/,/g, "");
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2.a Calculate chartTitles
   *
   */
  const chartTitles = {
    subTitles: {
      loadedOnDateTime: `@${dates.dateTimeFormat
        .format(new Date())
        .replace(/,/g, "")}`,
      timeSpan: `${dates.dateTimeFormat
        .format(dates.startingDateTime)
        .replace(/,/g, "")} - ${dates.dateTimeFormat
        .format(dates.endingDateTime)
        .replace(/,/g, "")}`,
    },
    title: `${formData.p_param.p_process_description} ${formData.p_param.p_line_number} Machine Rejects Pareto Chart`,
  };

  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix =
    formData.p_param.p_process_description +
    "-rejects-pareto-" +
    startingDateTimeFmt +
    "|" +
    endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);

  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   *
   */

  function setExpMenus(root) {
    // set Export Menu
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top",
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      dataSource: dataSet,
      dataFields: {
        rejectRatio: "Reject Ratio %",
        cumulativePercentage: "Culmulative %",
      },
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      csvOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      pdfOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
    });

    expPlugin.get("menu").get("items").shift();

    expPlugin.events.on("workbookready", function (event) {
      const wsName = event.workbook.SheetNames[0];
      const wsRange = event.workbook.Sheets[wsName]["!ref"];
      const lastRowNumber = parseInt(
        wsRange.split(":")[1].match(/\d+$/)[0],
        10
      );

      console.log("wsName", wsName);
      console.log("wsRange", wsRange);
      console.log("lastRowNumber", lastRowNumber);
      event.workbook.Sheets[wsName]["!cols"] = getAppropriateWidthForColumns(
        event.workbook.Sheets[wsName]
      );

      for (var row = 1; row < lastRowNumber; row++) {
        let cell =
          event.workbook.Sheets[wsName][
            sheetJs.utils.encode_cell({ c: 1, r: row })
          ];
        console.log("cell 0", cell);
        cell.z = '0.0000"%"';
        cell =
          event.workbook.Sheets[wsName][
            sheetJs.utils.encode_cell({ c: 1, r: row })
          ];
        console.log("cell 0", cell);
        cell.z = '#0.00"%"';
      }

      function getAppropriateWidthForColumns(worksheet) {
        const wsRange = worksheet["!ref"];
        const lastColumnNumber = sheetJs.utils.decode_col(
          wsRange.split(":")[1].match(/[a-zA-Z]+/g)[0]
        );
        const lastRowNumber = parseInt(
          wsRange.split(":")[1].match(/\d+$/)[0],
          10
        );
        let maxColumnLengths = [];

        for (let j = 0; j <= lastColumnNumber; j++) {
          const maxCellLengthForWholeColumn = Array.from(
            { length: lastRowNumber },
            (_, i) => i
          ).reduce((acc, i) => {
            const cell = worksheet[`${sheetJs.utils.encode_col(j)}${i + 1}`];

            // empty cell
            if (!cell) {
              return acc;
            }

            const charLength = cell.v.toString().length + 1;

            return acc > charLength ? acc : charLength;
          }, 0);

          maxColumnLengths.push({ wch: maxCellLengthForWholeColumn });
        }

        return maxColumnLengths;
      }
    });
  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      text: chartTitles.title,
      x: am5.p50,
    });
    // chart.children.unshift(mainTitle);

    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 2,
      }),
      paddingBottom: -5,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0,
    });

    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50,
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100,
    });

    subTitle.children.push(timespanLabel);
    subTitle.children.push(fetchedOnLabel);

    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
  }

  useLayoutEffect(() => {
    //createChart() logics in vue are implemented here.
    let root = am5.Root.new(chartId);
    root.utc = false;

    root.setThemes([am5themes_Animated.new(root)]);

    // Chart Creation
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        panX: true,
        panY: false,
        pinchZoomX: true,
        wheelX: "panX",
        wheelY: "none",
      })
    );

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04,
    });

    chart.zoomOutButton.set("forceHidden", true);

    // set export menu options for the chart.
    setExpMenus(root);

    setTitles(root, chart);

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "name",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 10,
        }),
        // tooltip needs to be made for tooltipLocation to work, but we don't want to show it so it's hidden
        // tooltipLocation locks the cursor a 0 - 1 location of each axis element (0.5 is middle)
        tooltip: am5.Tooltip.new(root, {
          forceHidden: true,
        }),
        tooltipLocation: 0.5,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      centerX: am5.p100,
      centerY: am5.p50,
      oversizedBehavior: "wrap-no-break",
      rotation: -45,
      textAlign: "right",
      maxWidth: 150,
    });
    xAxis.children.push(
      am5.Label.new(root, {
        fontWeight: "500",
        text: "Machines",
        textAlign: "center",
        x: am5.p50,
      })
    );

    // Create Y-axis 1 for RunTime
    let yAxes1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0.1,
        numberFormat: "#0.0000",
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 25,
          strokeOpacity: 0.7,
          stroke: am5.color("#343a40"),
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxes1.children.unshift(
      am5.Label.new(root, {
        fontWeight: "500",
        rotation: -90,
        text: "Reject Ratio %",
        textAlign: "center",
        y: am5.p50,
      })
    );

    // Create Y-axis 2 for OpRate
    let yAxes2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        max: 100,
        min: 0,
        numberFormat: "#'%",
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 25,
          opposite: true,
        }),
        strictMinMax: true,
        syncWithAxis: yAxes1,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxes2.children.push(
      am5.Label.new(root, {
        fontWeight: "500",
        text: "Cumulative %",
        textAlign: "center",
        rotation: 90,
        y: am5.p50,
      })
    );
    yAxes2.get("renderer").grid.template.set("forceHidden", true);
    console.log("dataset data", dataSet);
    //series 1 On Time (over RunTime yAxis)
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        clustered: false,

        name: "Reject Ratio %",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold] {valueY}",
        }),
        categoryXField: "name",
        valueYField: "rejectRatio",
        valueXField: "sortOrder",
        xAxis: xAxis,
        yAxis: yAxes1,
        // stacked: true, // Velmani Add
      })
    );

    series1.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      width: am5.percent(90),
    });

    series1.bullets.push((root) =>
      am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fontSize: 10,
          centerX: am5.percent(50),
          centerY: am5.percent(100),
          populateText: true,
        }),
      })
    );

    series1.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });
    series1.columns.template.adapters.add("stroke", (fill, target) => {
      return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });

    //series 2 Run Time (over RunTime yAxis) [runTime: object.measurement_time]
    let series2 = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        clustered: false,
        // stacked: true, // Velmani Add
        fill: chart.get("colors").getIndex(0),
        name: "Cumulative %",
        maskBullets: false,
        stroke: am5.color("#000000"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Pareto: [bold] {valueY}%",
          pointerOrientation: "up",
        }),

        categoryXField: "name",
        valueYField: "cumulativePercentage",

        xAxis: xAxis,
        yAxis: yAxes2,
      })
    );
    series2.strokes.template.setAll({
      strokeWidth: 2,
    });
    console.log("dataset data", dataSet);

    //series 3 Op Rate (over OpRate yAxis) [operationRate: object.op_rate,]
    series2.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series2.get("fill"),
          stroke: series2.get("stroke"),
        }),
      });
    });
    // the label text
    series2.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          centerX: am5.percent(50),
          populateText: true,
          text: "{valueY.formatNumber('##0.0')}%",
        }),
      });
    });
    /**** Below appears not requred, so commented - Velmani
   * both conditions executes same set of statemetns, and these statements
   * would anyway be execected in later steps in this program.
   
   xAxis.events.on("groupintervalchanged", () => {
     if (xAxis.getPrivate("groupInterval").timeUnit === "hour") {
       console.log("Here 1 in hour");
       xAxis.data.setAll(dataSet);
       series1OnTime.data.setAll(dataSet);
       series2RunTime.data.setAll(dataSet);
       series3OperationRate.data.setAll(dataSet);

       return;
     }
     console.log("Here 2 NOT in hour");
     xAxis.data.setAll(dataSet);
     series1OnTime.data.setAll(dataSet);
     series2RunTime.data.setAll(dataSet);
     series3OperationRate.data.setAll(dataSet);
   });
  
   */

    // DataProcessor steps may not be needed in React JS; - Velmani
    let dataProcessor = am5.DataProcessor.new(root, {
      numericFields: ["rejectRatio", "cumulativePercentage"],
    });
    dataProcessor.processMany(dataSet);

    // let targetEfficiencyRange = yAxes_opRate.createAxisRange(
    //   yAxes_opRate.makeDataItem({
    //     above: true,
    //     value: targetEfficiency,
    //   })
    // );

    // targetEfficiencyRange.get("label").setAll({
    //   background: am5.RoundedRectangle.new(root, {
    //     fill: am5.color("#1D8348"),
    //   }),
    //   dx: -10,
    //   fill: am5.color("#FFFFFF"),
    //   centerX: am5.p100,
    //   inside: true,
    //   text: "Target Efficiency",
    // });

    // targetEfficiencyRange.get("axisFill").setAll({
    //   location: 1,
    //   stroke: am5.color("#1D8348"),
    //   strokeWidth: 1,
    //   visible: true,
    // });

    let chartCursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        xAxis: xAxis,
      })
    );
    chartCursor.lineY.set("visible", false);

    let scrollBarsX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollBarsX);

    xAxis.data.setAll(dataSet);
    series1.data.setAll(dataSet);
    series2.data.setAll(dataSet);

    series1.appear(1000);
    series2.appear(1000);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (<div id={props.id} style={{ width: "100%", height: "600px" }}></div>);
}

export default ShowParetoRejectMachStopsChart;
