import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "assets/style/dark.scss";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import SideMenu from "Layouts/SideMenu";
import EffOpTimeChartPage from "pages/charts/EffOpTime/EffOpTimeChartPage";
import EffUnitChartPage from "pages/charts/EffUnit/EffUnitChartPage";
import MachineStopsTimeChartPage from "pages/charts/MachineStops/MachineStopsTimeChartPage";
import VACheckChartPage from "pages/charts/VAChecker/VACheckChartPage";
import ParetoMachStopsChartPage from "pages/charts/ParetoMachStops/ParetoMachStopsChartPage";
import TPAInsNGChartPage from "pages/charts/TPAInsNG/TPAInsNGChartPage";
import ParetoRejectMachStopsChartPage from "pages/charts/ParetoRejectsMach/ParetoRejectMachStopsChartPage";
import LineWaitTimeChartPage from "pages/charts/LineWaitChart/LineWaitTimeChartPage";
import HomePage from "pages/HomePage";
import PecaTop from "Layouts/PecaTop";
import MachinesPG from "pages/setups/machines.js";
import MachinesAddressesPG1 from "pages/setups/MachineAddress";
import PollDataArchives from "pages/Admin/Transactions/Poll_data_archives/PollDataArchives";
import CellAssembly from "pages/setups/CellAssembly/CellAssembly";
import TPADashboarsPage from "pages/setups/TPADashboards/TPADashboards";
import DxpDataLoggers from "pages/setups/DxpData/DxpDataLoggers";
import DxpDataLoggersLogDet from "pages/setups/DxpData/DxpDataLoggersLogDet";
import DxpDataLoggersLogDetEvent from "pages/setups/DxpData/DxpDataLoggersLogDetEvent";
import DxpDataLoggersEventTrig from "pages/setups/DxpData/DxpDataLoggersEventTrig";
import EventsPG from "pages/setups/DxpConfigs/Events";
import CommunicationsPG from "pages/setups/DxpConfigs/Communications";
import TaggingPG from "pages/setups/DxpConfigs/Tags";
import PollDataFiles from "pages/Admin/PollData/PollDataFiles/PollDataFiles";
import PollDataColumns from "pages/Admin/PollData/PollDataFileColumns/PollDataColumns";
import PollDataErrors from "pages/Admin/PollData/PollDataErrors/PollDataErrors";
import PollMachErrors from "pages/Admin/PollData/DeviceExplorerLoggingSettingError/DeviceExplorerLoggingSettingError";
import ErrorSummary from "pages/Admin/PollData/ErrorSummary/ErrorSummary";
import LookupValues from "pages/setups/LookupValues";
import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  return (
    <PrimeReactProvider>
      <PecaTop />
      <Router>
        <SideMenu />
        <Routes>
          <Route path="/">
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/setups/machines">
            <Route index element={<MachinesPG title="PDCS Machines" />} />
          </Route>
          <Route path="/setups/machineAddress">
            <Route
              index
              element={<MachinesAddressesPG1 title="Machine Addresses" />}
            />
          </Route>
          <Route path="/setups/LookupValues">
            <Route index element={<LookupValues title="Inquire Lookup" />} />
          </Route>
          <Route path="/setups/DxpData/DxpDataLoggers">
            <Route index element={<DxpDataLoggers title="Loggings" />} />
          </Route>
          <Route path="/setups/DxpData/DxpDataLoggersLogDet">
            <Route
              index
              element={<DxpDataLoggersLogDet title="Loggings Details" />}
            />
          </Route>
          <Route path="/setups/DxpData/DxpDataLoggersLogDetEvent">
            <Route
              index
              element={<DxpDataLoggersLogDetEvent title="Event Details" />}
            />
          </Route>
          <Route path="/setups/DxpData/DxpDataLoggersEventTrig">
            <Route
              index
              element={<DxpDataLoggersEventTrig title="Event Triggers" />}
            />
          </Route>

          <Route path="/charts/EffOpTime">
            <Route index element={<EffOpTimeChartPage />} />
          </Route>

          <Route path="/charts/EffUnit">
            <Route index element={<EffUnitChartPage />} />
          </Route>
          <Route path="/charts/MachineStops">
            <Route index element={<MachineStopsTimeChartPage />} />
          </Route>
          <Route path="/charts/VAChecker">
            <Route index element={<VACheckChartPage />} />
          </Route>
          <Route path="/charts/ParetoMachStops">
            <Route index element={<ParetoMachStopsChartPage />} />
          </Route>
          <Route path="/charts/TPAInsNG">
            <Route index element={<TPAInsNGChartPage />} />
          </Route>
          <Route path="/charts/ParetoRejectsMach">
            <Route index element={<ParetoRejectMachStopsChartPage />} />
          </Route>
          <Route path="/charts/LineWaitChart">
            <Route index element={<LineWaitTimeChartPage />} />
          </Route>
          <Route path="/Admin/Transactions/Poll_data_archives">
            <Route
              index
              element={<PollDataArchives title="Poll Data Archives" />}
            />
          </Route>
          <Route path="/setups/CellAssembly/CellAssembly">
            <Route index element={<CellAssembly title="Cell Assembly" />} />
          </Route>
          <Route path="/setups/TPADashboards/TPADashboards">
            <Route index element={<TPADashboarsPage title="TPA Dashboard" />} />
          </Route>
          <Route path="/setups/DxpConfigs/Events">
            <Route index element={<EventsPG title="Events" />} />
          </Route>
          <Route path="/setups/DxpConfigs/Communications">
            <Route
              index
              element={<CommunicationsPG title="Communications" />}
            />
          </Route>
          <Route path="/setups/DxpConfigs/Tags">
            <Route index element={<TaggingPG title="Tags" />} />
          </Route>
          <Route path="/Admin/PollData/PollDataFiles">
            <Route index element={<PollDataFiles title="Poll Data Files" />} />
          </Route>
          <Route path="/Admin/PollData/PollDataFileColumns">
            <Route
              index
              element={<PollDataColumns title="Poll Data Columns" />}
            />
          </Route>
          <Route path="/Admin/PollData/PollDataErrors">
            <Route
              index
              element={<PollDataErrors title="Poll Data Errors" />}
            />
          </Route>
          <Route path="/Admin/PollData/DeviceExplorerLoggingSettingError">
            <Route
              index
              element={
                <PollMachErrors title="Device Explorer Logging Setting Error" />
              }
            />
          </Route>
          <Route path="/Admin/PollData/ErrorSummary">
            <Route
              index
              element={
                <ErrorSummary title="Dxp Poll Data Interface Errors Summary" />
              }
            />
          </Route>
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
