import React, { useEffect, useState } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowVACheckChart from "pages/charts/VAChecker/ShowVACheckChart";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";

function VACheckFormControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [targetEfficiency, setTargetEfficiency] = useState();
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =
    useState(true);
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);
        console.log(firstRowProcessId);
        const filteredMachines = props.machines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        setMachines(filteredMachines);
        setMachineId(
          filteredMachines.length > 0 ? filteredMachines[0].id : null
        );
        console.log(filteredMachines);

        //setMachines(filteredMachines);

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.machines]);

  const handleLoadChart = async () => {
    let selectedMachineRec = machines.find((dt) => dt.id == machineId);
    console.log(selectedMachineRec);
    const p_param = {
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };
    console.log(p_param);

    setIsLoading(true);
    setHasAttemptedFetch(true); 
    setChartForm({
      ...chartForm,
      machineDesc: selectedMachineRec.description,
      data: p_param,
    });
    console.log(chartForm);

    await loadApi(p_param, "/api/chart/getVaChkrRunTotalData")
      .then(async (result) => {
        //    await loadApi(p_param, "GetHourlyMachineEffOpTimeByIdAndTimespan").then(async (result) => {
        if (result) {
          let successFlag = result.data[0];
          if (successFlag === "Y") {
            if (result.data[1].length > 0) {
              setChartData(result.data[1]);
              console.log("result.data[1]", result.data[1]);
              setTargetEfficiency(result.data[2]);
              console.log("result.data[2]", result.data[2]);
              setWasDataFetchingSuccessful(false);
              setError(false);
            } else {
              setErrorStatus(204);
              setWasDataFetchingSuccessful(true);
            }
          } else {
            setError(true);
          }
          setIsLoading(false);
        } else {
          setWasDataFetchingSuccessful(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setErrorStatus(err.response ? err.response.status : null);
        setIsLoading(false);
        setError(true);
      });
  };

  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);

    //setMachines(filteredMachines);
  };

  const handleMachineChange = (e) => {
    setMachineId(parseInt(e.target.value));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getProcessControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label
          className= "form-label"
        >
          Process
        </label>
        <div className="form-check" onChange={handleProcessChange}>
          {props.processes.map((input) => (
            <div key={input.id}>
              <input
                className="form-check-input"
                type="radio"
                id={`${props.chartId}ProcessRadBtn${input.id}`}
                name={`${props.chartId}ProcessRadBtn`}
                value={input.id}
                checked={processId === input.id}
                disabled={isLoading}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getMachineControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label" htmlFor="controlItemSelect">
          Machine
        </label>

        <div className="form-check" onChange={handleMachineChange}>
          {machines.map((input) => (
            <div key={input.id}>
              <input
                className="form-check-input"
                type="radio"
                id={`${props.chartId}MachineRadBtn${input.id}`}
                name={`${props.chartId}MachineRadBtn`}
                value={input.id}
                checked={machineId === input.id}
                disabled={isLoading}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}MachineRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "StartDateTime"}
          name={props.chartId + "StartDateTime"}
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
          disabled={isLoading}
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "EndDateTime"}
          name={props.chartId + "EndDateTime"}
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
          disabled={isLoading}
        />
      </div>
    </React.Fragment>
  );

  const getLoadButtonControl = () => (
    <React.Fragment>
        <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
          <button
            type="button"
            className="btn btn-primary load-button"
            id={props.chartId + "chartAreaId"}
            onClick={handleLoadChart}
            disabled={isLoading}
          >
            <i
              className={
                isLoading ? "pi pi-spin pi-spinner" : "pi pi-chart-bar"
              }
            ></i>
            <span>{isLoading ? "Loading" : "Load"}</span>
          </button>
      </div>
    </React.Fragment>
  );

  return (
    <div className="col">
      <div className="row justify-content-center m-0">
        {getProcessControl()}
        {getMachineControl()}
        {getStartEndDateControls()}
        {getLoadButtonControl()}
      </div>
      {error && (
        <div className="row my-3">
          <div className="col-12">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
              
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  <li>No data Found</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )} {error && hasAttemptedFetch &&  (
        <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
              VA Checker Running Total Charts  are Unavailable
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  {errorStatus === 204 && (
                    <li>No matching result found.</li>
                  )}
                  {errorStatus === 500 && (
                    <li>Data/export unavailable.</li>
                  )}
                  {errorStatus && ![204, 500].includes(errorStatus) && (
                    <li>Unable to pull data</li>
                  )}
                  {!errorStatus && (
                    <li>An unknown error occurred. Please try again later.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && chartData.length === 0 && hasAttemptedFetch && !error && (
        <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                No Matching Results!
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <p>There are no results found.</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!wasDataFetchingSuccessful && (
        <div className="row mt-5 justify-content-md-center">
          <div className="col">
            <ShowVACheckChart
              chartData={chartData}
              chartForm={chartForm}
              targetEff={targetEfficiency}
              id={props.chartId + "OptimeChart1"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default VACheckFormControlListSelector;
