import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "assets/am-charts-5/_css/amcharts5-export.css";
import "assets/am-charts-5/_css/amcharts5-export.min.css";
import moment from "moment";
import * as sheetJs from "xlsx";

function TPAInsNGChart(props) {
  // Define data
  let ngStatsRows = props.ngStatsRows;
  let ngDataRows = props.ngDataRows;
  let categoryName = props.categoryName;
  let formData = props.chartForm;

  let chartId = props.id;

  console.log("TPAInsNGChart ngStatsRows :", ngStatsRows);
  console.log("TPAInsNGChart ngDataRows :", ngDataRows);
  console.log("TPAInsNGChart categoryName :", categoryName);
  console.log("TPAInsNGChart formData:", formData);

  /** Map data provided by API (as done by Dot Net program ). This can be changed later to work best for React JS */

  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
    dataSets: {
      byCalendarDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      }),
      byProductionDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      })
    },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat("en-US", {
      year: "2-digit" /*** To check: in chart-form-controls-mixin.js, it is  "2-digit" ***/,
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    startingDateTime: new Date(formData.data.p_start_datetime),
    endingDateTime: new Date(formData.data.p_end_datetime),
  };

  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /***
   * Step 1 Calculate Avg values
   *         return new HourlyMachineEfficiency
   */
  let timeDiff =
    dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, "") -
    dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, "");
  console.log("timeDiff", timeDiff);

  const startingDateTimeFmt = dates.dateTimeFormat
    .format(dates.startingDateTime)
    .replace(/,/g, "");
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat
    .format(dates.endingDateTime)
    .replace(/,/g, "");
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   *
   */

  /****
   * Step 2.a Calculate chartTitles
   *
   */
  // const chartTitles = {
  //   title: `${formData.data.machineDesc} - Resistence ${categoryName}`,
  //   subTitles: {
  //     timeSpan: `${startingDateTimeFmt} - ${endingDateTimeFmt}`,
  //   },
  // };
  const chartTitles = {
    subTitles: {
      loadedOnDateTime: `@${dates.dateTimeFormat
        .format(new Date())
        .replace(/,/g, "")}`,
      timeSpan: `${startingDateTimeFmt} - ${endingDateTimeFmt}`,
    },
    title: `${formData.data.machineDesc} - Resistence ${categoryName}`,
  };
  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix =
    formData.machineDesc +
    "-efficiency-by-time-" +
    startingDateTimeFmt +
    "|" +
    endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);

  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   *
   */

  function setExpMenus(root) {
    // set Export Menu
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top",
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      dataSource: ngDataRows,
      dataFields: {
        description: "Sensor",
        value: "Reject Counts",
      },
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      csvOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      pdfOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
    });

    expPlugin.get("menu").get("items").shift();
  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      paddingTop:50,
      text: chartTitles.title,
      x: am5.p50,
    });
   

    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 3,
      }),
      paddingBottom: 0,
      paddingLeft: 15,
      paddingRight: -10,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0,
    });

    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50,
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100,
      y: -50
    });
    let rejectStatsLabel = am5.Label.new(root, {
      centerX: am5.p0,
      text: chartTitles.subTitles.averageStats,
      x: am5.p0,
    });

    subTitle.children.push(timespanLabel);
    subTitle.children.push(fetchedOnLabel);
    subTitle.children.push(rejectStatsLabel);
    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
  }

  useLayoutEffect(() => {
    //createChart() logics in vue are implemented here.
    let root = am5.Root.new(chartId);
    root.utc = false;

    root.setThemes([am5themes_Animated.new(root)]);

    // Chart Creation
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 25,
        paddingTop: 0,
        panX: false,
        panY: false,

        wheelX: "none",
        wheelY: "none",
      })
    );

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04,
    });

    chart.zoomOutButton.set("forceHidden", true);

    // set export menu options for the chart.
    setExpMenus(root);

    setTitles(root, chart);

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,##0",
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0,
      maxPosition: 0.9,
    });
    console.log("ngStat Rows: ", ngStatsRows);
    let xAxisTitleLabel = am5.Label.new(root, {
      fontWeight: "500",

      text: `Total Count of ${categoryName} Rejects: ${ngStatsRows[0].ng_count}`,
      textAlign: "center",
      x: am5.p50,
    });
    xAxis.children.push(xAxisTitleLabel);

    // Create Y-axis 1 for RunTime
    let yAxes = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "description",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          minGridDistance: 20,
        }),
        // tooltip needs to be made for tooltipLocation to work, but we don't want to show it so it's hidden
        // tooltipLocation locks the cursor a 0 - 1 location of each axis element (0.5 is middle)
        tooltip: am5.Tooltip.new(root, {
          forceHidden: true,
        }),
        tooltipLocation: 0.5,
      })
    );
    yAxes.get("renderer").labels.template.setAll({
      multiLocation: 0.5,
      rotation: -30,
    });
    yAxes.children.unshift(
      am5.Label.new(root, {
        fontWeight: "500",
        rotation: -90,
        text: "Sensors",
        textAlign: "center",
        y: am5.p50,
      })
    );

    //series 1  (over TPA NG yAxis)
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        categoryYField: "description",

        legendLabelText: "[{stroke}]{name}[/]",
        name: "Value",
        sequencedInterpolation: true,
        valueXField: "value",

        xAxis: xAxis,
        yAxis: yAxes,
        // stacked: true, // Velmani Add
      })
    );

    series1.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
    });
    series1.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });
    series1.columns.template.adapters.add("stroke", (fill, target) => {
      return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });

    // DataProcessor steps may not be needed in React JS; - Velmani
    let dataProcessor = am5.DataProcessor.new(root, {
      numericFields: ["value", "sort_value"],
    });
    dataProcessor.processMany(ngDataRows);

    let chartCursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        xAxis: xAxis,
      })
    );
    chartCursor.lineY.set("visible", false);

    yAxes.data.setAll(ngDataRows);
    series1.data.setAll(ngDataRows);

    series1.appear(100);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [ngDataRows]);

  return <div id={props.id} style={{ width: "100%", height: "600px" }}></div>;
}

export default TPAInsNGChart;
