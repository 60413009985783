//export const baseUrl =  'http://89.116.187.234:10053'; /** Radix Backend Oracle Server */
const radixTest = process.env.REACT_APP_RADIX_ORACLE_URL;
const devDB = process.env.REACT_APP_CLIENT_DEV_DB_URL;
const UAT = process.env.REACT_APP_CLIENT_UAT_DB_URL;
const prodDB = process.env.REACT_APP_CLIENT_PROD_DB_URL;
export const getBaseUrl = (service) => {
    console.log('Param service is:', service);
  switch (service) {
    case 'TEST':
      return radixTest;
    case 'DYBQR53':
      return devDB;
    case 'DYBQR93':
      return UAT;
    case 'DYBQR03':
      return prodDB;
    default:
      return radixTest;
  }
};
//console.log('Radix Oracle URL:', radixTest);
console.log('DevDB URL:', devDB);

export const pecaDbName = 'TEST';
//export const pecaDbName = 'DYBQR53';
//export const pecaDbName = 'DYBQR93';
//export const pecaDbName = 'DYBQR03';
export const baseUrl = getBaseUrl(pecaDbName);
console.log('pecaDbName, baseUrl is:', pecaDbName, baseUrl);
