import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import 'assets/am-charts-5/_css/amcharts5-export.css';
import 'assets/am-charts-5/_css/amcharts5-export.min.css';
import moment from 'moment';
import * as sheetJs from "xlsx";


function ShowEffOpTimeChart(props) {
  // Define data
  let chartData = props.chartData;
  let formData = props.chartForm;
  let targetEfficiency = props.targetEff;
  let chartId = props.id;

  console.log("EffOpTimeChart chartData:", chartData);
  console.log("EffOpTimeChart formData:", formData);
  console.log("EffOpTimeChart targetEfficiency:", targetEfficiency);

/*************
  ** Map data provided by API (as done by Dot Net program ). This can be changed later to work best for React JS ****
     public HourlyMachineEfficiencyMap()
    {
      Map(p => p.RunningTime).ToColumn("on_time", false);
      Map(p => p.OperationRate).ToColumn("op_rate", false);

      Map(p => p.OnTime).ToColumn("measurement_time", false);
      Map(p => p.UnitsProduced).ToColumn("prod_count", false);
      Map(p => p.MaxUnitsThatCouldBeProduced).ToColumn("max_hourly_production", false);
      Map(p => p.CalendarDateTime).ToColumn("h_interval", false);
    }
  }
*************/
  const dataSet = chartData.map((object) => {
    return {
      //machineId: object.machine_id, /** To check: machine_id does not exist in the values passed.  so, commented it.  Velmani */
      runningTime: object.on_time,
      operationRate: object.op_rate,
      onTime: object.measurement_time,
      h_interval: object.h_interval,
      chartingDateTime: moment(object.h_interval).valueOf()
    };
  });

  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
 /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
    dataSets: {
      byCalendarDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      }),
      byProductionDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      })
    },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat('en-US',
      {
        year: '2-digit', 
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }),
    startingDateTime: new Date(formData.data.p_start_datetime),
    endingDateTime: new Date(formData.data.p_end_datetime)
  }


  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /*** 
   * Step 1 Calculate Avg values
   *         return new HourlyMachineEfficiency
     public HourlyMachineEfficiency AverageHourlyMachineEfficiency
    {
      get
      {
        if (HourlyMachineEfficiences.Count == 0)
        {
          return new HourlyMachineEfficiency();
        }

        return new HourlyMachineEfficiency
        {
          OperationRate = decimal.Round(HourlyMachineEfficiences.Average(x => x.OperationRate), 2),
          RunningTime   = decimal.Round(HourlyMachineEfficiences.Average(x => x.RunningTime),   2),

          OnTime = decimal.Round(HourlyMachineEfficiences.Average(x => x.OnTime ?? 0), 2)
        };
      }
    }
   **************/

  let avgOnTime = (dataSet.reduce((val, item) => Number(val) + Number(item.onTime), 0) / dataSet.length).toFixed(2);
  console.log("avgOnTime", avgOnTime);

  let avgOperationRate = (dataSet.reduce((val, item) => Number(val) + Number(item.operationRate), 0) / dataSet.length).toFixed(2);
  console.log("avgOperationRate", avgOperationRate);

  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   * 
   */

  const startingDateTimeFmt = dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, '');
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, '');
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2.a Calculate chartTitles
   * 
   */
  const chartTitles = {
    subTitles: {
      averageStats: `Avg Runtime: ${avgOnTime}min \nAvg Op Rate: ${avgOperationRate}%`,
      loadedOnDateTime: `@${dates.dateTimeFormat.format(new Date()).replace(/,/g, '')}`,
      timeSpan: `${startingDateTimeFmt} - ${endingDateTimeFmt}`,
    },
    title: `${formData.machineDesc} Efficiency`
  }

  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix = formData.machineDesc + "-efficiency-by-time-" +
    startingDateTimeFmt + "|" + endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);


  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   * 
   */

  function setExpMenus(root) {

    // set Export Menu
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top"
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      dataSource: dataSet,
      dataFields: {
        runningTime: "Run Time (min)",
        onTime: "On Time (min)",
        operationRate: "Op Rate",
        h_interval: "Hour"
      },
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      csvOptions: {
        disabled: true
      },
      htmlOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      pdfOptions: {
        disabled: true
      },
      pdfdataOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      }
    });

    expPlugin.get("menu").get("items").shift();

        expPlugin.events.on("workbookready", function (event) {
          const wsName = event.workbook.SheetNames[0]
          const wsRange = event.workbook.Sheets[wsName]["!ref"];
          const lastRowNumber = parseInt(wsRange.split(":")[1].match(/\d+$/)[0], 10);

	 console.log("wsName", wsName);
	 console.log("wsRange", wsRange);
	 console.log("lastRowNumber", lastRowNumber);
          event.workbook.Sheets[wsName]["!cols"] = getAppropriateWidthForColumns(event.workbook.Sheets[wsName]);

          for (var row = 1; row < lastRowNumber; row++) {
            let cell = event.workbook.Sheets[wsName][sheetJs.utils.encode_cell({ c: 0, r: row })];
	 console.log("cell 0", cell);
            cell.z = "#,##0";

            cell = event.workbook.Sheets[wsName][sheetJs.utils.encode_cell({ c: 1, r: row })]
	 console.log("cell 1", cell);
            cell.z = "#,##0";

            cell = event.workbook.Sheets[wsName][sheetJs.utils.encode_cell({ c: 2, r: row })]
	 console.log("cell 2", cell);
            cell.z = '00.00"%"';

            cell = event.workbook.Sheets[wsName][sheetJs.utils.encode_cell({ c: 3, r: row })]
	 console.log("cell 3", cell);
            const cellValue = cell.v;
            cell.f = `=DATEVALUE(LEFT("${cellValue}", 10)) + TIMEVALUE(MID("${cellValue}", 12, 8))`
            cell.z = "mm/dd/yyyy hh:mm";
          }

          function getAppropriateWidthForColumns(worksheet) {
            const wsRange = worksheet["!ref"];
            const lastColumnNumber = sheetJs.utils.decode_col(wsRange.split(":")[1].match(/[a-zA-Z]+/g)[0]);
            const lastRowNumber = parseInt(wsRange.split(":")[1].match(/\d+$/)[0], 10);
            let maxColumnLengths = [];

            for (let j = 0; j <= lastColumnNumber; j++) {
              const maxCellLengthForWholeColumn = Array.from(
                { length: lastRowNumber },
                (_, i) => i
              ).reduce((acc, i) => {
                const cell = worksheet[`${sheetJs.utils.encode_col(j)}${i + 1}`];

                // empty cell
                if (!cell) {
                  return acc;
                }

                const charLength = cell.v.toString().length + 1;

                return acc > charLength ? acc : charLength;
              }, 0);

              maxColumnLengths.push({ wch: maxCellLengthForWholeColumn });
            }

            return maxColumnLengths;
          }
        });
   

  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      text: chartTitles.title,
      x: am5.p50
    });   


    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 3
      }),
      paddingBottom: -5,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0
    });   



    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100
    });
    let averageStatsLabel = am5.Label.new(root, {
      centerX: am5.p0,
      text: chartTitles.subTitles.averageStats,
      x: am5.p0
    });

    subTitle.children.push(timespanLabel);
    subTitle.children.push(fetchedOnLabel);
    subTitle.children.push(averageStatsLabel);
    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
 
  }


  useLayoutEffect(() => {

    //createChart() logics in vue are implemented here.
    let root = am5.Root.new(chartId);
    root.utc = false;

    root.setThemes([am5themes_Animated.new(root)]);


    // Chart Creation
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      panX: true,
      panY: false,
      pinchZoomX: true,
      wheelX: "panX",
      wheelY: "zoomX"
    }));



    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04
    });

    chart.zoomOutButton.set("forceHidden", true);


      
    // set export menu options for the chart.
    setExpMenus(root);

    setTitles(root, chart);

    // Create X-Axis
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {
        count: 1,
        timeUnit: "hour"
      },
      gridIntervals: [
        { timeUnit: "hour", count: 1 },
        { timeUnit: "hour", count: 3 },
        { timeUnit: "hour", count: 6 },
        { timeUnit: "hour", count: 12 },
        { timeUnit: "day", count: 1 },
        { timeUnit: "day", count: 2 },
        { timeUnit: "day", count: 3 },
        { timeUnit: "day", count: 4 },
        { timeUnit: "day", count: 5 },
        { timeUnit: "week", count: 1 },
        { timeUnit: "month", count: 1 },
        { timeUnit: "month", count: 2 },
        { timeUnit: "month", count: 3 },
        { timeUnit: "month", count: 6 },
        { timeUnit: "year", count: 1 },
        { timeUnit: "year", count: 2 },
        { timeUnit: "year", count: 5 }
      ],
      groupData: true,
      groupIntervals: [
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 },
        { timeUnit: "month", count: 1 }
      ],
      markUnitChange: false,
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 25
      }),
      tooltip: am5.Tooltip.new(root, {}),
      tooltipLocation: 0.5
    }));

    xAxis.get("dateFormats")["hour"] = "MMM ddt HH:mm";
    xAxis.get("dateFormats")["day"] = "MMM ddt";
    xAxis.get("dateFormats")["month"] = "MMM yy";

    xAxis.get("renderer").grid.template.setAll({
      location: 0.5,
    });

    xAxis.get("renderer").labels.template.setAll({
      centerX: am5.p100,
      centerY: am5.p50,
      multiLocation: 0.5,
      rotation: -45
    });


    // Create Y-axis 1 for RunTime
    let yAxes_runTime = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 25,
        strokeOpacity: 0.7,
        stroke: am5.color("#343a40")
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));


    yAxes_runTime.children.unshift(am5.Label.new(root, {
      fontWeight: '500',
      rotation: -90,
      text: "Runtime (min)",
      textAlign: 'center',
      y: am5.p50
    }));

   
    // Create Y-axis 2 for OpRate
    let yAxes_opRate = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      max: 100,
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 25,
        opposite: true
      }),
      strictMinMax: true,
      tooltip: am5.Tooltip.new(root, {})
    }));
    yAxes_opRate.setAll({
      numberFormat: "#'%",
      syncWithAxis: yAxes_runTime
    });


    yAxes_opRate.children.push(am5.Label.new(root, {
      fontWeight: '500',
      text: "Op Rate (%)",
      textAlign: 'center',
      rotation: 90,
      y: am5.p50
    }));
    yAxes_opRate.get("renderer").grid.template.set("forceHidden", true);
   


    //series 1 On Time (over RunTime yAxis)
    let series1OnTime = chart.series.push(am5xy.ColumnSeries.new(root, {
      clustered: false,
      legendLabelText: "[{stroke}]{name}[/]",
      name: "On Time (Min)",
      valueXField: "chartingDateTime",
      valueYField: "onTime",
      valueYGrouped: "sum",
      xAxis: xAxis,
      yAxis: yAxes_runTime
    //  stacked: true // Velmani Add
    }));

    series1OnTime.columns.template.setAll({
      fill: am5.color("#2196f3"),
      fillOpacity: 0.3,
      strokeWidth: 2
    });



    //series 2 Run Time (over RunTime yAxis) [runTime: object.measurement_time]
    let series2RunTime = chart.series.push(am5xy.ColumnSeries.new(root, {
      clustered: false,
  //    stacked: true, // Velmani Add
      fill: am5.color("#1A5276"),
      legendLabelText: "[{stroke}]{name}[/]",
      name: "Runtime (Min)",
      stroke: am5.color("#1A5276"),
      tooltip: am5.Tooltip.new(root, {
        labelText: "Minutes: [bold] {valueY}"
      }),
      layer: 0,
      valueXField: "chartingDateTime",
      valueYField: "runningTime",
      valueYGrouped: "sum",
      xAxis: xAxis,
      yAxis: yAxes_runTime
    }));
    series2RunTime.columns.template.setAll({
      fillOpacity: 1,
      strokeWidth: 0
    });



    //series 3 Op Rate (over OpRate yAxis) [operationRate: object.op_rate,]
    let series3OperationRate = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      clustered: false,
      fill: am5.color("#FA2A55"),
      legendLabelText: "[{stroke}]{name}[/]",
      name: "Op Rate %",
      stroke: am5.color("#FA2A55"),
      tension: 0.7,
      tooltip: am5.Tooltip.new(root, {
        labelText: "Op Rate: [bold] {valueY}%"
      }),
      valueXField: "chartingDateTime",
      valueYField: "operationRate",
      valueYGrouped: "average",
      xAxis: xAxis,
      yAxis: yAxes_opRate
    }));
    series3OperationRate.strokes.template.setAll({ strokeWidth: 4 });




  /**** Below appears not requred, so commented - Velmani
   * both conditions executes same set of statemetns, and these statements
   * would anyway be execected in later steps in this program.
   
   xAxis.events.on("groupintervalchanged", () => {
     if (xAxis.getPrivate("groupInterval").timeUnit === "hour") {
       console.log("Here 1 in hour");
       xAxis.data.setAll(dataSet);
       series1OnTime.data.setAll(dataSet);
       series2RunTime.data.setAll(dataSet);
       series3OperationRate.data.setAll(dataSet);

       return;
     }
     console.log("Here 2 NOT in hour");
     xAxis.data.setAll(dataSet);
     series1OnTime.data.setAll(dataSet);
     series2RunTime.data.setAll(dataSet);
     series3OperationRate.data.setAll(dataSet);
   });
  
   */


    // DataProcessor steps may not be needed in React JS; - Velmani
    let dataProcessor = am5.DataProcessor.new(root, {
      dateFields: ["chartingDateTime"],
      dateFormat: "i",
      numericFields: ["onTime", "runningTime"]
    });
    dataProcessor.processMany(dataSet);
    dataProcessor.processMany(dataSet);


    let targetEfficiencyRange = yAxes_opRate.createAxisRange(yAxes_opRate.makeDataItem({
      above: true,
      value: targetEfficiency
    }));

    targetEfficiencyRange.get("label").setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color("#1D8348")
      }),
      dx: -10,
      fill: am5.color("#FFFFFF"),
      centerX: am5.p100,
      inside: true,
      text: "Target Efficiency"
    });

    targetEfficiencyRange.get("axisFill").setAll({
      location: 1,
      stroke: am5.color("#1D8348"),
      strokeWidth: 1,
      visible: true
    });

    let chartCursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none",
      xAxis: xAxis
    }));
    chartCursor.lineY.set("visible", false);

    let scrollBarsX = am5.Scrollbar.new(root, {
      orientation: "horizontal"
    });

    chart.set("scrollbarX", scrollBarsX);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      centerY: am5.percent(50),
      marginTop: -25,
      paddingBottom: 40,
      x: am5.percent(50),
      y: am5.percent(100)
    }));


    xAxis.data.setAll(dataSet);
    series1OnTime.data.setAll(dataSet);
    series2RunTime.data.setAll(dataSet);
    series3OperationRate.data.setAll(dataSet);

    legend.data.setAll(chart.series.values);
    series1OnTime.appear(1000);
    series2RunTime.appear(1000);
    series3OperationRate.appear(1000);
    xAxis.zoom(0, 1);
    chart.appear(1000, 100);


    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <div id={props.id} style={{ width: "100%", height: "600px" }} ></div>
  )
}

export default ShowEffOpTimeChart;
