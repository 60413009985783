import axios from 'axios';
import { baseUrl } from './envhttp';

axios.defaults.baseURL = baseUrl;

export const getDataApi = (title) => {
    console.log("saveApi: title", title)
    return axios.get(title);
}

export const listApi = (title) => {
    return axios.get(title);
}

export const loadApi = (inputs, pUrl) => {
    return axios.post(pUrl, inputs);
}

export const saveApi = (inputs, pUrl) => {
    console.log("saveApi: inputs", inputs)
    console.log("saveApi: pUrl", pUrl)
    return axios.post(pUrl, inputs);
}

export const getDataWithInputsApi = (title, processId) => {
    return axios.get(title, { params: { processId } })
}

export const getDataforTimeChartApi = (title, inputs) => {
    return axios.get(title, { params: { inputs } })
}