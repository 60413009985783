import "assets/scss/dashboard.scss";

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import VACheckFormControlListSelector from "pages/charts/VAChecker/VACheckFormControlListSelector";
import { getDataApi } from "services/appApi";

function VACheckChartPage() {
  const [processes, setProcesses] = useState([]);
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areMultipleChartAreasShown, setAreMultipleChartAreasShown] =
    useState(false);
  const [loadingErrors, setLoadingErrors] = useState([]);

  const toggleSecondChartArea = () => {
    setAreMultipleChartAreasShown(!areMultipleChartAreasShown);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let res = await getDataApi("/api/chart/getVaChkrProcMachines");
        //        let res = await getDataApi("EffOpTimePageLoad");
        if (res) {
          let successFlag = res.data[0];
          if (successFlag === "Y") {
            setProcesses(res.data[1]);
            console.log("1", res.data[1]);
            setMachines(res.data[2]);
            console.log("2", res.data[2]);
            setIsLoading(false);
          } else {
            setLoadingErrors([{ message: res.data[2] || 'Unknown error occurred' }]);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setLoadingErrors([{ message: 'No response from server' }]);
        }
      } catch (err) {
        console.log(err);
        const errorMessage = err.response?.status === 500 
        ? 'Data/export unavailable' 
        : 'Unable to pull data, please try again later'; 
      setLoadingErrors([{ message: errorMessage }]);
      }
    };
    fetchData();
  }, []);

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">VA Checker Running Total Chart </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">

            <span className="normal-font">VA Checker Running Total Chart</span>
            <button
          type="button"
          id="btnSecondChart"
          className={
            areMultipleChartAreasShown
              ? "btn btn-danger mt-2 mt-md-0 ms-md-3"
              : "btn btn-success mt-2 mt-md-0 ms-md-3"
          }
          onClick={toggleSecondChartArea}
        >
          
          <i
            className={
              areMultipleChartAreasShown
                ? "fa fa-square-minus me-2" 
                : "fa fa-square-plus me-2"   
            }
          ></i>
          {areMultipleChartAreasShown ? "Remove" : "Add"} 2<sup>nd</sup> Chart
        </button>
          </h2>
        </div>
      </React.Fragment>
    );
  };

  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        {loadingErrors.length > 0 && (
        <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
              VA Checker Running Total Charts  are Unavailable
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error, index) => (
                    <li key={index}>{error.message}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      </React.Fragment>
    );
  };

  const getFormControls = (chartId) => {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row justify-content-center">
            <div className="col ps-2 pe-2">
              {!isLoading && (
                <div id="chart-container-1" className="chart-container">
                  <VACheckFormControlListSelector
                    processes={processes}
                    machines={machines}
                    chartId={chartId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="dashboard">
      <div className="dashboardContainer">
        <div className="charts">
          {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
          <div className="row">
            {loadingErrors.length > 0
              ? getErrrorMsgContents()
              : getFormControls("VACheckerChart1")}

            {loadingErrors.length <= 0 && areMultipleChartAreasShown
              ? getFormControls("VACheckerChart2")
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VACheckChartPage;
