import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "./Machines.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { InputSwitch } from "primereact/inputswitch";

import { getDataApi, saveApi,loadApi } from "../../services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const MachinesAddressesPG1 = ({ machines, selectedMachineId, onChange }) => {
  let emptyRow = {
    machine_address_id: null,
    machine_id: null,
    machine_name: "",
    machine_description: "",
    theoretical_capacity: "",
    production_speed: "",
    target_efficiency: "",
    machine_address_code: "",
    machine_address_name: "",
    is_production_count: "Y",
    is_duration_second: "N",
    is_duration_minute: "N",
    active_flag: "Y",
    sort_key: null,
    plc_hmi_mgmt_type_code:""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  //   const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [machineName, setMachineName]=useState("");
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [lineNumber, setLineNumber]=useState(0);
  const toast = useRef(null);
  const dt = useRef(null);

  const handleLoadData = async () => {
    const p_param = {
      p_machine_id: null,
      p_machine_name_like: machineName,
      //p_line_number: lineNumber,
   };
   console.log(p_param);
    try {
      setIsLoading(true);
      const response = await loadApi(p_param,"/api/setup/machineAddresses/selRows");
      console.log("Fetched Data:", response.data);
      console.log(response.data[0]);
      console.log(response.data[1]);
      if (response.data.length > 0) {
        let successFlag = response.data[0];
        if (successFlag === "Y") {
          console.log("success=Y");
          setRows(response.data[1]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(false);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
          const errorInfo = response.data[2];
          // Set the error message and display the dialog
          setErrorMessage(errorInfo);
          setErrorDialogVisible(true);
        }
        //setSearch(response.data);
      } else {
        //setRows(null);
        setRows([]);
        setIsLoading(false);
        setWasDataFetchingSuccessful(true);
      }

      //setRows(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //it open pop-up to create new data
  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  //It closes that pop-up
  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const saveRow = async () => {
    setSubmitted(true);

    if (row.machine_address_code.trim()) {
      let _rows = [...rows];
      let _row = { ...row };

      try {
        if (row.machine_address_id) {
          /*** Update Row */
          const response = await saveApi(
            row,
            "/api/setup/machineAddresses/updRow"
          );
          console.log(response);
          let successFlag = response.data[0];
          console.log("Mahi 1", successFlag);
          if (successFlag === "Y") {
            const index = findIndexById(row.machine_address_id);
            console.log("index",index);
            _rows[index] = _row;
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Machine Updated",
              life: 3000,
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Machine Not Updated",
              life: 3000,
            });
          }
        } else {
          /*** Insert Row */
          console.log("Mahi 3");
          const response = await saveApi(
            row,
            "/api/setup/machineAddresses/insRow"
          );
          console.log("Mahi 3.1");
          //   let successFlag =
          //     response.data[0]; /** This contains value of Y or N */
          console.log(response.data);
          let successFlag = "Y";
          console.log(response);
          console.log("Mahi 2", successFlag);
          if (successFlag === "Y") {
            _row.machine_address_id =
              response.data[1]; /** It contains newly created Id */
            _rows.push(_row);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Machine Created",
              life: 3000,
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Machine Not Created",
              life: 3000,
            });
          }
        }

        setRows(_rows);
        setRowDialog(false);
        setRow(emptyRow);
      } catch (error) {
        console.log("Errlogor saving machine:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Machine Save Failed",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        life: 3000,
      });
    }
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].machine_address_id === id) {
        index = i;
        break;
      }
    }
    console.log(index);
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Machine Addresses
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> Machine Addresses </span><br/><br/>
            {/* <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px; 
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div> */}
         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }

  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
              Dxp Poll Data Interface Errors Summary table is Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>
                      error
                    </li>
                  ))}
  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _row = { ...row };
    _row[`${name}`] = val;

    setRow(_row);
  };

  const header = (
    <div
      className="table-header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "3rem",
        alignContent: "center",
      }}
    >
      <div
        style={{
          width: window.innerWidth / 3,
          display: "flex",
          justifyContent: "flex-start",
          marginRight: "110px",
        }}
      >
        {/* <span className="p-input-icon-left">
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            style={{ width: "300px" }}
          />
        </span> */}
      </div>
      <div
      //   style={{
      //     width: window.innerWidth / 3,
      //     alignContent: "center",
      //   }}
      // >
      //   <h2>Manage Machines</h2>
      // </div>
      // <div
      //   style={{
      //     width: window.innerWidth / 3,
      //     display: "flex",
      //     justifyContent: "flex-end",
      //   }}
      >
        <Button
          // label="New"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </div>
  );
  const getSearchControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Search</label>
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const getMachineNameControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Machine Name</label>
            <InputText 
            type="text"
            id = "MacName"
            name="MacName" 
            onChange={(e) => setMachineName(e.target.value)}
            placeholder="Enter machine name"
            className="input-outline" />
             </div>
    </React.Fragment>
  );

  const getLineNumberControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Line Number</label>
            <InputText
            type="number"
            id="LineNum"
            name="LineNum"
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setLineNumber(value);
              }
            }}
            placeholder="Enter line number"
            min="0" // Ensures only positive integers are allowed
            className="input-outline"
            />
      </div>
    </React.Fragment>
  );
  // const getLineNumberControl = () => (
  //   <React.Fragment>
  //     <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
  //     <label className="form-label">Line Number</label>
  //           <InputText
  //           type="text"
  //           id="LineNum"
  //           name="LineNum"
  //           onChange={(e) => setLineNumber(e.target.value)}
  //           placeholder="Enter line number"
  //           className="input-outline"
  //           />
  //     </div>
  //   </React.Fragment>
  // );
  const getGoButtonControl = () => (
    <React.Fragment>
        <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
          <button
            type="button"
            className="btn btn-primary load-button"
            id={"chartAreaId"}
            onClick={handleLoadData}
            disabled={isLoading}
          >
            {isLoading ? (
                  <i className="pi pi-spin pi-spinner"></i>
                ) : null}
            <span>{isLoading ? "Loading" : "Go"}</span>
          </button>
        </div>
    </React.Fragment>
    );
  

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editRow(rowData)}
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()}  
          {getMachineNameControl()}
          {/* {getLineNumberControl()} */}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
      <div className="card">
        <DataTable
          ref={dt}
          value={rows}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="machine_address_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} machines"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No machines found"
          stripedRows
          showGridlines
          scrollable
          scrollHeight="flex"
          style={{
            fontSize: "14px",
          }}
        >
          <Column field="machine_name" header="Machine Name" sortable></Column>
          <Column
            field="machine_address_code"
            header="Address Code"
            sortable
          ></Column>
          <Column
            field="machine_address_name"
            header="Address Name"
            sortable
          ></Column>
          <Column
            field="plc_hmi_mgmt_type_code"
            header="Data Mgmt"
            sortable
          ></Column>
          <Column
            field="address_type_code"
            header="Address Type"
            sortable
          ></Column>
          <Column
            field="address_value_type_code"
            header="Value Type"
            sortable
          ></Column>
          <Column
            field="opc_contents"
            header="OPC Contents"
            sortable
          ></Column>
          <Column
            field="opc_external_ref"
            header="OPC Reference"
            sortable
          ></Column>
          <Column
            field="opc_model"
            header="OPC Model"
            sortable
          ></Column>
          <Column
            field="is_machine_stop"
            header="Is MachStop"
            sortable
          ></Column>
          <Column
            field="machine_wait_time_sort_order"
            header="Sort Order"
            sortable
          ></Column>
          <Column
            field="active_flag"
            header="Active"
            sortable
          ></Column>
          <Column
            header="Action"
            body={actionBodyTemplate}
            style={{width:'0.75rem' }}
          ></Column>
        </DataTable>
      </div>
       )}
       </div>
       </div>

      <Dialog
        visible={rowDialog}
        style={{ width: "450px" }}
        header="Machine Details"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <div className="p-field">
          <label htmlFor="machine_name" style={{ fontWeight: "bold" }}>
            Machine Name
          </label>
          <InputText
            id="machine_name"
            value={row.machine_name}
            onChange={(e) => onInputChange(e, "machine_name")}
            // required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !row.machine_name,
            })}
          />
          {submitted && !row.machine_name && (
            <small className="p-error">Machine Name is required.</small>
          )}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="machine_address_code" style={{ fontWeight: "bold" }}>
          Address Code
          </label>
          <InputText
            id="machine_address_code"
            value={row.machine_address_code}
            onChange={(e) => onInputChange(e, "machine_address_code")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.machine_address_code,
            // })}
          />
          {submitted && !row.machine_address_code && (
            <small className="p-error">Address Code is required.</small>
          )}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="machine_address_name" style={{ fontWeight: "bold" }}>
          Address Name
          </label>
          <InputText
            id="machine_address_name"
            value={row.machine_address_name}
            onChange={(e) => onInputChange(e, "machine_address_name")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.machine_address_name,
            // })}
          />
          {submitted && !row.machine_address_name && (
            <small className="p-error">Address Name is required.</small>
          )}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="plc_hmi_mgmt_type_code" style={{ fontWeight: "bold" }}>
          Data Mgmt
          </label>
          <InputText
            id="plc_hmi_mgmt_type_code"
            value={row.plc_hmi_mgmt_type_code}
            onChange={(e) => onInputChange(e, "plc_hmi_mgmt_type_code")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.plc_hmi_mgmt_type_code,
            // })}
          />
          {/* {submitted && !row.plc_hmi_mgmt_type_code && (
            <small className="p-error">Data Mgmt is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="address_type_code" style={{ fontWeight: "bold" }}>
          Address Type
          </label>
          <InputText
            id="address_type_code"
            value={row.address_type_code}
            onChange={(e) => onInputChange(e, "address_type_code")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.address_type_code,
            // })}
          />
          {/* {submitted && !row.address_type_code && (
            <small className="p-error">Address Type is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="address_value_type_code" style={{ fontWeight: "bold" }}>
          Value Type
          </label>
          <InputText
            id="address_value_type_code"
            value={row.address_value_type_code}
            onChange={(e) => onInputChange(e, "address_value_type_code")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.address_value_type_code,
            // })}
          />
          {/* {submitted && !row.address_value_type_code && (
            <small className="p-error">Value Type is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="opc_contents" style={{ fontWeight: "bold" }}>
          OPC Contents
          </label>
          <InputText
            id="opc_contents"
            value={row.opc_contents}
            onChange={(e) => onInputChange(e, "opc_contents")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.opc_contents,
            // })}
          />
          {/* {submitted && !row.opc_contents&& (
            <small className="p-error">OPC Contents is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="opc_external_ref" style={{ fontWeight: "bold" }}>
          OPC Reference
          </label>
          <InputText
            id="opc_external_ref"
            value={row.opc_external_ref}
            onChange={(e) => onInputChange(e, "opc_external_ref")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.opc_external_ref,
            // })}
          />
          {/* {submitted && !row.opc_external_ref&& (
            <small className="p-error">OPC Reference is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="opc_model" style={{ fontWeight: "bold" }}>
          OPC Model
          </label>
          <InputText
            id="opc_model"
            value={row.opc_model}
            onChange={(e) => onInputChange(e, "opc_model")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.opc_model,
            // })}
          />
          {/* {submitted && !row.opc_model && (
            <small className="p-error">OPC Model is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="is_machine_stop" style={{ fontWeight: "bold" }}>
          Is MachStop
          </label>
          <InputText
            id="is_machine_stop"
            value={row.is_machine_stop}
            onChange={(e) => onInputChange(e, "is_machine_stop")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.is_machine_stop,
            // })}
          />
          {/* {submitted && !row.is_machine_stop && (
            <small className="p-error">Is MachStop is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        <div className="p-field">
          <label htmlFor="machine_wait_time_sort_order" style={{ fontWeight: "bold" }}>
          Sort Order
          </label>
          <InputText
            id="machine_wait_time_sort_order"
            value={row.machine_wait_time_sort_order}
            onChange={(e) => onInputChange(e, "machine_wait_time_sort_order")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.machine_wait_time_sort_order,
            // })}
          />
          {/* {submitted && !row.machine_wait_time_sort_order && (
            <small className="p-error">Sort Order is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>
        
        <div className="p-field">
          <label htmlFor="active_flag" style={{ fontWeight: "bold" }}>
          Active
          </label>
          <InputText
            id="active_flag"
            value={row.active_flag}
            onChange={(e) => onInputChange(e, "active_flag")}
            // required
            // autoFocus
            // className={classNames({
            //   "p-invalid": submitted && !row.active_flag,
            // })}
          />
          {/* {submitted && !row.active_flag && (
            <small className="p-error">Active is required.</small>
          )} */}
          {/* <p>{selectedOption}</p> */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={hideDialog}
            style={{ marginRight: "20px" }}
          />
          <Button
            label="Save"
            icon="pi pi-check"
            className="p-button-success"
            onClick={saveRow}
          />
        </div>
      </Dialog>
      <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default MachinesAddressesPG1;
