import React from "react";
import { Link } from "react-router-dom";
import "assets/scss/sidemenu.scss";
import { Menubar } from "primereact/menubar";

function TopMenu() {
  const itemRenderer = (item) => (
    <Link
      to={item.link}
      className="flex align-items-center p-menuitem-link"
      style={{ textDecoration: "none" }}
    >
      <i className={`${item.icon} me-2`} />
      <span className="mx-0" style={{ textDecoration: "none" }}>
        {item.label}
      </span>
    </Link>
  );

  const items = [
    {
      label: "LD-PDCS",
      template: () => (
        <span
          className="inline-flex align-items-center gap-1 px-1 py-1 text-xl"
          style={{
            color: "white",
            textDecoration: "underline dotted",
            backgroundColor: "black",
            fontWeight: "normal",
          }}
        >
          LD-PDCS
        </span>
      ),
    },
    {
      label: "Charts",
      items: [
        {
          label: "Machine",
          template: () => <span className="dropdown-heading">Machine</span>,
        },
        {
          label: "Efficiency - Operation Time",
          link: "/charts/EffOpTime",
          icon: "fas fa-chart-line",
          template: itemRenderer,
        },
        {
          label: "Efficiency - Units Produced",
          link: "/charts/EffUnit",
          icon: "fas fa-chart-line",
          template: itemRenderer,
        },
        {
          label: "Machine Stops",
          link: "/charts/MachineStops",
          icon: "fas fa-chart-pie",
          template: itemRenderer,
        },
        {
          label: "CA | VA Checker Running Totals",
          link: "/charts/VAChecker",
          icon: "fas fa-chart-area",
          template: itemRenderer,
        },
        {
          label: "TPA | Inspection NG",
          link: "/charts/TPAInsNG",
          icon: "fas fa-chart-bar",
          template: itemRenderer,
        },
        { separator: true },
        {
          label: "Line",
          template: () => <span className="dropdown-heading">Lines</span>,
        },
        {
          label: "Pareto | Machine Stops",
          link: "/charts/ParetoMachStops",
          icon: "fas fa-chart-line",
          template: itemRenderer,
        },
        {
          label: "Pareto | Reject Ratio",
          link: "/charts/ParetoRejectsMach",
          icon: "fas fa-chart-line",
          template: itemRenderer,
        },
        {
          label: "Wait Times",
          link: "/charts/LineWaitChart",
          icon: "fas fa-chart-bar",
          template: itemRenderer,
        },
        {
          label: "Weight Checker",
          link: "#",
          icon: "fas fa-chart-bar",
          template: itemRenderer,
        },
      ],
    },
    {
      label: "Dashboards",
      items: [
        {
          label: "Cell Assembly",
          link: "/setups/CellAssembly/CellAssembly",
          icon: "fas fa-tachometer-alt",
          template: itemRenderer,
        },
        {
          label: "Top Plate Assembly",
          link: "/setups/TPADashboards/TPADashboards",
          icon: "fas fa-tachometer-alt",
          template: itemRenderer,
        },
      ],
    },
    {
      label: "Operator Entry",
      items: [
        {
          label: "Top Plate Assembly",
          link: "#",
          icon: "fas fa-pencil-alt",
          template: itemRenderer,
        },
      ],
    },
    {
      label: "Admin Pages",
      items: [
        {
          label: "Poll Data",
          items: [
            {
              label: "Poll Data Files",
              link: "/Admin/PollData/PollDataFiles",
              icon: "fas fa-database",
              template: itemRenderer,
            },
            {
              label: "Poll Data Err Summary",
              link: "/Admin/PollData/ErrorSummary",
              icon: "fas fa-exclamation-circle",
              template: itemRenderer,
            },
            {
              label: "Device Explorer Logging Setting Error",
              link: "/Admin/PollData/DeviceExplorerLoggingSettingError",
              icon: "fas fa-book",
              template: itemRenderer,
            },
            {
              label: "Poll Data Col Map Errors",
              link: "/Admin/PollData/PollDataFileColumns",
              icon: "fas fa-map",
              template: itemRenderer,
            },
            {
              label: "Poll Data Errors",
              link: "/Admin/PollData/PollDataErrors",
              icon: "fas fa-times-circle",
              template: itemRenderer,
            },
          ],
        },
        {
          label: "Transactions",
          items: [
            {
              label: "Poll Data Archives",
              link: "/Admin/Transactions/Poll_data_archives",
              icon: "fas fa-archive",
              template: itemRenderer,
            },
          ],
        },
        {
          label: "Setup",
          items: [
            {
              label: "Lookup Values",
              link: "/setups/LookupValues",
              icon: "fas fa-cogs",
              template: itemRenderer,
            },
            {
              label: "Machines",
              link: "/setups/machines",
              icon: "fas fa-industry",
              template: itemRenderer,
            },
            {
              label: "Machine Addresses",
              link: "/setups/MachineAddress",
              icon: "fas fa-map-marker-alt",
              template: itemRenderer,
            },
          ],
        },
        {
          label: "DxpConfigs",
          items: [
            {
              label: "Events",
              link: "/setups/DxpConfigs/Events",
              icon: "fas fa-calendar-check",
              template: itemRenderer,
            },
            {
              label: "Communications",
              link: "/setups/DxpConfigs/Communications",
              icon: "fas fa-comments",
              template: itemRenderer,
            },
            {
              label: "Tags",
              link: "/setups/DxpConfigs/Tags",
              icon: "fas fa-tags",
              template: itemRenderer,
            },
            {
              label: "Loggings",
              link: "/setups/DxpData/DxpDataLoggers",
              icon: "fas fa-file-alt",
              template: itemRenderer,
            },
            {
              label: "Logging Details",
              link: "/setups/DxpData/DxpDataLoggersLogDet",
              icon: "fas fa-list-alt",
              template: itemRenderer,
            },
            {
              label: "Event Details",
              link: "/setups/DxpData/DxpDataLoggersLogDetEvent",
              icon: "fas fa-calendar-day",
              template: itemRenderer,
            },
            {
              label: "Event Triggers",
              link: "/setups/DxpData/DxpDataLoggersEventTrig",
              icon: "fas fa-bell",
              template: itemRenderer,
            },
            {
              label: "Import Configs",
              link: "#",
              icon: "fas fa-upload",
              template: itemRenderer,
            },
          ],
        },
      ],
    },
  ];

  return <Menubar model={items} />;
}

export default TopMenu;
