import React from 'react'

function HomePage() {
  return (
    <div className='dashboard'>
      <div className='dashboardContainer'>

        <div className="charts" id="app">
          <div className="row d-flex justify-content-center m-5">
            <div className="col-auto">
              <div className="card shadow-sm border-primary">
                <div className="card-header bg-primary text-white">
                  <h2 className="mb-0">
                    PECA LD PDCS
                  </h2>
                </div>
                <div className="card-body text-center">
                  <h3>
                    <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i> Welcome
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage;