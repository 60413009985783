import React, { useEffect, useState, useRef } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowMachStopcolumnChart from "pages/charts/MachineStops/ShowMachStopcolumnChart.jsx";
import ShowMachStopPieChart from "pages/charts/MachineStops/ShowMachStopPieChart.jsx";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function MachineStopsControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] = useState(true);
  const [error, setError] = useState(false);
  const [emptyRowError, setEmptyRowError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const selectedDataType = useRef("Count"); 
  const [machineRenderedAsRadio, setMachineRenderedAsRadio] = useState(false);
  const [resultCountDataRows, setResultCountDataRows] = useState();
  const [resultDurnDataRows, setResultDurnDataRows] = useState();
  const [cachedData, setCachedData] = useState({});
  const [isLoadButtonClicked, setIsLoadButtonClicked] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [processChanged, setProcessChanged] = useState(false);
  const [machineChanged, setMachineChanged] = useState(false);
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [dummyState, setDummyState] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);

        const filteredMachines = props.machines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        setMachines(filteredMachines);
        setMachineId(
          filteredMachines.length > 0 ? filteredMachines[0].id : null
        );

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);

        setMachineRenderedAsRadio(filteredMachines.length <= 2);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.machines]);

  useEffect(() => {
    if (isLoadButtonClicked) {
      handleLoadChart();
      setIsLoadButtonClicked(false);
      setHasFetchedData(true);
    }
  }, [isLoadButtonClicked]);

  useEffect(() => {
    if (!processChanged && !machineChanged && !startDateChanged && !endDateChanged && hasFetchedData) {
      handleLoadChart();
    }
  }, [selectedDataType.current]);
  const handleLoadChart = async () => {
    if (!selectedDataType.current || !machineId || !startDate || !endDate) {
      console.error('Required parameters are missing');
      setError(true);
      return;
    }
  
    const selectedMachineRec = machines.find((dt) => dt.id === machineId);
    if (!selectedMachineRec) {
      console.error('Selected machine record not found');
      setError(true);
      return;
    }
  
    const p_param = {
      p_data_type: selectedDataType.current,
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };
  
    console.log('Parameters:', p_param);
    setChartForm({
      ...chartForm,
      machineDesc: selectedMachineRec?.description || "Winding Machine #6",
      data: p_param,
    });
  
    const cacheKey = `${machineId}_${startDate}_${endDate}`;
    if (!isLoadButtonClicked && cachedData[cacheKey] && cachedData[cacheKey][selectedDataType.current]) {
      const cachedResult = cachedData[cacheKey][selectedDataType.current];
      if (Array.isArray(cachedResult)) {
        if (selectedDataType.current === 'Count') {
          setResultCountDataRows(cachedResult);
        } else if (selectedDataType.current === 'Duration') {
          setResultDurnDataRows(cachedResult);
        }
        setError(false);
        setWasDataFetchingSuccessful(false);
        return;
      }
    }
  
    setIsLoading(true);
    setHasAttemptedFetch(true); 
    try {
      const result = await loadApi(p_param, "/api/chart/getMachStopChrtData");
      console.log('API Result:', result);
      
      if (result) {
        const successFlag = result.data[0];
        const resultData1 = result.data[1] || []; 
        const resultData2 = result.data[2] || [];
  
        if (successFlag === "Y") {
          console.log('Result Count:', resultData1);
          console.log('Result Duration:', resultData2);
          if (Array.isArray(resultData1) && resultData1.length > 0) {
            const newCache = {
              ...cachedData,
              [cacheKey]: {
                Count: resultData1,
                Duration: resultData2,
              },
            };
            setCachedData(newCache);
            setResultCountDataRows(resultData1);
          } else {
            console.log('No valid data returned for Count. resultData1:', resultData1);
            setResultCountDataRows([]); 
            setErrorStatus(204);
          }
          setResultDurnDataRows(resultData2); 
          setError(false);
          if (resultData1.length === 0 && resultData2.length === 0) {
            setEmptyRowError(true);
          } else {
            setEmptyRowError(false);
          }
          setWasDataFetchingSuccessful(false);
        } else {
          setError(true); 
        }
      } else {
        setWasDataFetchingSuccessful(true); 
      }
    } catch (err) {
      console.error('API Error:', err);
      setErrorStatus(err.response ? err.response.status : null);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };  
  const handleDataTypeChange = (newDataType) => {
    selectedDataType.current = newDataType; 
    setDummyState(dummyState + 1); 
  };

  const handleLoadButtonClick = () => {
    setError(false);
    setWasDataFetchingSuccessful(true);
    setResultCountDataRows(null);
    setResultDurnDataRows(null);
    setIsLoadButtonClicked(true);
    setProcessChanged(false);
    setMachineChanged(false);
    setStartDateChanged(false);
    setEndDateChanged(false);
  };

  const handleExport = async () => {
    const selectedMachineRec = machines.find((dt) => dt.id === machineId);
    if (!selectedMachineRec) {
      console.error('Selected machine record not found');
      setError(true);
      return;
    }
    const p_param = {
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };

    setIsLoading1(true);
    setWasDataFetchingSuccessful(true);

    try {
      const result = await loadApi(p_param, "/api/chart/getMachStopRptData");

      if (result) {
        let successFlag = result.data[0];
        if (successFlag === "Y") {
          if (result.data[1].length > 0) {
            setData(result.data[1]);
            exportDataToExcel(result.data[1], startDate, endDate);
            setWasDataFetchingSuccessful(false);
            setError(false);
          } else {
            setErrorStatus(204);
            setWasDataFetchingSuccessful(true);
          }
        } else {
          setError(true);
        }
        setIsLoading1(false);
      } else {
        setWasDataFetchingSuccessful(true);
        setIsLoading1(false);
      }
    } catch (err) {
      console.error("Error", err);
      setErrorStatus(err.response ? err.response.status : null);
      setIsLoading1(false);
      setError(true);
    }
  };

const exportDataToExcel = async (data, startDate, endDate) => {
  const selectedMachineRec = machines.find((dt) => dt.id === machineId);
    if (!selectedMachineRec) {
      console.error('Selected machine record not found');
      setError(true);
      return;
    }
    const now = new Date();
    const formattedDate = now.toISOString().replace(/T/, '_').replace(/\..+/, '');
    const fileName = `${selectedMachineRec.description.replace(/\s+/g, '_')}_stops_${startDate.replace(/\s+/g, '_')}_${endDate.replace(/\s+/g, '_')}.xlsx`;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Machine Stops');
    const header = `${selectedMachineRec.description} Machine Stops Log Between ${startDate} To ${endDate}`;
    worksheet.addRow([header]);
    const columnCount = Object.keys(data[0]).length;
    if (columnCount > 1) {
      worksheet.mergeCells(1, 1, 1, columnCount); 
    }
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);
    worksheet.columns = headers.map((header, colIndex) => ({
      width: Math.max(
        header.length,
        ...data.map(row => row[header]?.toString().length || 10)
      ) + 2, 
    }));
    worksheet.getRow(2).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' },
      };
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
    });
    data.forEach((row) => {
      worksheet.addRow(Object.values(row));
    });
    const rowCount = worksheet.rowCount;
    for (let i = 1; i <= rowCount; i++) { 
      worksheet.getRow(i).eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
      });
    }
    worksheet.views = [
      {
        state: 'frozen',
        ySplit: 2,
      },
    ]
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  };

  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);
    setProcessChanged(true); 

    const filteredMachines = props.machines.filter(
      (dt) => dt.process_id == selectedProcessId
    );
    setMachines(filteredMachines);
    setMachineRenderedAsRadio(filteredMachines.length <= 2);
    setMachineId(filteredMachines.length > 0 ? filteredMachines[0].id : null);
  };

  const handleMachineChange = (e) => {
    setMachineId(parseInt(e.target.value));
    setMachineChanged(true); 
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setStartDateChanged(true); 
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setEndDateChanged(true);
  };

  const getDataTypeControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label"> Data Type </label>
        <div className="form-check">
          <input
            inputId={`${props.chartId}RblDataType0`}
            className="form-check-input"
            type="radio"
            value="Count"
            checked={selectedDataType.current === "Count"}
            name={`${props.chartId}RblDataType`}
            onChange={(e) => handleDataTypeChange(e.target.value)}
             disabled={isLoading || isLoading1}
          />
          <label
            className="form-check-label"
            htmlFor={`${props.chartId}RblDataType0`}
          >
            Count
          </label>
        </div>
        <div className="form-check">
          <input
            inputId={`${props.chartId}RblDataType1`}
            className="form-check-input"
            type="radio"
            value="Duration"
            checked={selectedDataType.current === "Duration"}
            name={`${props.chartId}RblDataType`}
            onChange={(e) => handleDataTypeChange(e.target.value)}
            disabled={isLoading || isLoading1}
          />
          <label
            className="form-check-label"
            htmlFor={`${props.chartId}RblDataType1`}
          >
            Duration
          </label>
        </div>
      </div>
    </React.Fragment>
  );

  const getProcessControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className={props.processes.length === 1 ? "form-label mb-3" : "form-label"}>
          Process
        </label>
        <div className="form-check">
          {props.processes.map((input) => (
            <div key={input.id}>
              <input
                inputId={`${props.chartId}ProcessRadBtn${input.id}`}
                className="form-check-input"
                type="radio"
                name={`${props.chartId}ProcessRadBtn`}
                value={input.id}
                onChange={handleProcessChange}
                checked={processId == input.id}
                disabled={isLoading || isLoading1}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getMachineControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label" htmlFor="controlItemSelect">
          Machine
        </label>
        {machineRenderedAsRadio ? (
          <div className="form-check">
            {machines.map((input) => (
              <div key={input.id}>
                <input
                  inputId={`${props.chartId}MachineRadBtn${input.id}`}
                  className="form-check-input"
                  type="radio"
                  name={`${props.chartId}MachineRadBtn`}
                  value={input.id}
                  onChange={handleMachineChange}
                  checked={machineId === input.id}
                  disabled={isLoading || isLoading1}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${props.chartId}MachineRadBtn${input.id}`}
                >
                  {input.description}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <select
            className="form-select"
            id={`${props.chartId}MachineDrpDnList`}
            name={`${props.chartId}MachineDrpDnList`}
            onChange={handleMachineChange}
            value={machineId || ""}
            disabled={isLoading || isLoading1}
          >
            {machines.map((option) => (
              <option key={option.id} value={option.id}>
                {option.description}
              </option>
            ))}
          </select>
        )}
      </div>
    </React.Fragment>
  );

  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "StartDateTime"}
          name={props.chartId + "StartDateTime"}
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
          disabled={isLoading || isLoading1}
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "EndDateTime"}
          name={props.chartId + "EndDateTime"}
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
          disabled={isLoading || isLoading1}
        />
      </div>
    </React.Fragment>
  );

  const getLoadButtonControl = () => (
    <React.Fragment>
      <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
        <button
          type="button"
          className="btn btn-primary load-button"
          id={props.chartId + "chartAreaId"}
          onClick={() => handleLoadButtonClick()}
          disabled={isLoading || isLoading1}
        >
          <i
            className={
              isLoading ? "pi pi-spin pi-spinner" : "pi pi-chart-bar"
            }
          ></i>
          <span>{isLoading ? "Loading" : "Load"}</span>
        </button>
      </div>
    </React.Fragment>
  );

  const getExportButtonControl = () => (
    <React.Fragment>
      <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
        <button
          type="button"
          className="btn btn-success load-button"
          id={props.chartId + "chartAreaId"}
          onClick={() => handleExport()}
          disabled={isLoading || isLoading1}
        >
          <i
            className={
              isLoading1 ? "pi pi-spin pi-spinner" : "pi pi-file-excel"
            }
          ></i>
          <span>{isLoading1 ? "Exporting" : "Export"}</span>
        </button>
      </div>
    </React.Fragment>
  );

  return (
    <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getDataTypeControl()}
          {getProcessControl()}
          {getMachineControl()}
          {getStartEndDateControls()}
          {getLoadButtonControl()}
          {getExportButtonControl()}
        </div>
        {/* {error && (
          <div className="row mt-8 justify-content-md-center">
            <div className="col-12">
              <div className="card border-secondary">
                <div className="card-header bg-dark text-white">
                  Machine Stops charts are Unavailable
                </div>
                <div className="card-body card-body-error rounded-bottom">
                  <ul>
                    <li>No data Found</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {error && hasAttemptedFetch &&  (
         <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                Machine Efficiency By Operation Time Charts are Unavailable
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  {/* Conditional rendering based on error status */}
                  {errorStatus === 204 && (
                    <li>No matching result found</li>
                  )}
                  {errorStatus === 500 && (
                    <li>Data/export unavailable</li>
                  )}
                  {errorStatus && ![204, 500].includes(errorStatus) && (
                    <li>Unable to pull data, please try again later</li>
                  )}
                  {!errorStatus && (
                    <li>An unknown error occurred. Please try again later.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Render a message if data is empty */}
      {!isLoading  && hasAttemptedFetch && !error && emptyRowError &&(
         <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
              No Matching Results!
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <p>There are no results found.</p>
              </div>
            </div>
          </div>
        </div>
      )}

        {!wasDataFetchingSuccessful  && !error && !emptyRowError && (
          <div className="row my-3">
            <div className="col-6 col-lg mb-3 mb-lg-0">
              <ShowMachStopcolumnChart
                resultCountDataRows={resultCountDataRows}
                resultDurnDataRows={resultDurnDataRows}
                formData={chartForm}
                id={props.chartId + "Column Chart"}
              />
            </div>
            <div className="col-6 col-lg">
              <ShowMachStopPieChart
                resultCountDataRows={resultCountDataRows}
                resultDurnDataRows={resultDurnDataRows}
                formData={chartForm}
                id={props.chartId + "Pie Chart"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MachineStopsControlListSelector;
