import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import './DxpTable.css';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { getDataApi, saveApi } from "../../../services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const EventsPG = () => {
  let emptyRow = {
    event_id: null,
    GroupName: "",
    TableName: "",
    EventMonitorCycle: 0,
    bEndDataLogging: "Y",
    LogDbType: "",
    LoggingPath: "",
    OdbcUID: "",
    OdbcPWD: "",
    bDiskIOByte: 0,
    DiskIOCount: 0,
    RequestTag: "",
    ReplyTag: "",
    CsvDivCount: 0,
    CsvDivMidTime: 0,
    CsvDivMinCycle: 0,
    bCsvDivTriger: "N",
    CsvNameRule: "",
    Prefix: "",
    Suffix: "",
    StragePeriod: 0,
    CsvDivStart: "",
    BufferCount: 0,
    StartEnable: "N",
    CsvDivTag: "",
    DynamicCycleTag: "",
    UseUpdate: "N",
    bUnitInsert: "N",
    CsvDivUpdateDate: "",
    CsvDivTime2: 0,
    CsvDivTime3: 0,
    CSVDomain: "",
    CSVUID: "",
    CSVPWD: "",
    DiskIOTimeOut: 0,
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);

  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/dxpEvents/selRows");
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
        } else {
          setRows([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchRows();
  }, []);

  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const editRow = (rowData) => {
    setRow({ ...rowData });
    setRowDialog(true);
  };


  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].event_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Events
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> Events </span><br/><br/>
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px;
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div>
         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }
  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded mr-2"
          onClick={() => editRow(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
        </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
        <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">Events</div> */}
          <DataTable
            ref={dt}
            value={rows}
            scrollable scrollHeight="flex"    
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            dataKey="event_id"
            className="custom-datatable-border"
            tableStyle={{ minWidth: '50rem' }}
            paginator
            rows={pecaPageRows}
            rowsPerPageOptions={pecaRowsPerPageOptions}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
            globalFilter={globalFilter}
            header={header}
            responsiveLayout="scroll"
            stripedRows
            showGridlines
          >
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{width:'0.75rem' }}
            ></Column>
            <Column
              field="group_name"
              header="Group Name"
              sortable
            ></Column>
            <Column
              field="file_name"
              header="File Name"
              sortable
            ></Column>
            <Column
              field="event_monitor_cycle"
              header="Event Cyle(Sec)"
              sortable
            ></Column>
            <Column
              field="b_end_data_logging"
              header="Stop Data Logging"
              sortable
            ></Column>
            <Column
              field="log_db_type"
              header="Log Type"
              sortable
            ></Column>
            <Column
              field="logging_path"
              header="File Path"
              sortable
            ></Column>
            <Column
              field="csv_div_min_cycle"
              header="Cycle Min"
              sortable
            ></Column>
            <Column
              field="csv_name_rule"
              header="File Name Rule"
              sortable
            ></Column>
        
          </DataTable>
         

          <Dialog
            visible={rowDialog}
            style={{ width: "450px" }}
            header="Event Details"
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="group_name">Group Name</label>
              <InputText
                id="group_name"
                value={row.group_name}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="file_name">File Name</label>
              <InputText
                id="file_name"
                value={row.file_name}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="event_monitor_cycle">Event Monitor Cycle</label>
              <InputNumber
                id="event_monitor_cycle"
                value={row.event_monitor_cycle}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="b_end_data_logging">Stop Data Logging</label>
              <InputNumber
                id="b_end_data_logging"
                value={row.b_end_data_logging}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="log_db_type">Log Type</label>
              <InputText
                id="log_db_type"
                Value={row.log_db_type}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="logging_path">File Path</label>
              <InputText
                id="logging_path"
                Value={row.logging_path}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="csv_div_min_cycle">Cycle(Min)</label>
              <InputText
                id="csv_div_min_cycle"
                Value={row.csv_div_min_cycle}
                readOnly
                className="p-disabled"
              />
            </div>
            <div className="field">
              <label htmlFor="csv_name_rule">File Name Rule</label>
              <InputText
                id="csv_name_rule"
                Value={row.csv_name_rule}
                readOnly
                className="p-disabled"
              />
            </div>
 
            {/* Add more fields as needed based on the row object structure */}
          </Dialog>
          <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
        </div>
         )}
      </div>
    </div>
  );
};

export default EventsPG;
