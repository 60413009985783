import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "assets/am-charts-5/_css/amcharts5-export.css";
import "assets/am-charts-5/_css/amcharts5-export.min.css";
import moment from "moment";
import * as sheetJs from "xlsx";

function ShowLineWaitTimeChart(props) {
  // Define data
  let chartData = props.chartData;
  let formData = props.chartForm;

  let chartId = props.id;

  let yAxisRanges = [];
  let scrollBarsYAxisRanges = [];

  console.log("ShowParetoMachStopsChart chartData:", chartData);
  console.log("ShowParetoMachStopsChart formData:", formData);

  /** Map data provided by API (as done by Dot Net program ). This can be changed later to work best for React JS */
  const dataSetTimes = chartData.map((object) => {
    return {
      category: object.machine_id + "-" + object.description,
      value: object.value,
      machine: object.machine_description,
      labelText: object.description,
      waitTimeSortOrder: object.wait_time_sort_order,
      machWaitTimeSortOrder: object.mach_wait_time_sort_order,
      data_type: object.data_type,
    };
  });
  const dataSetMachines = chartData.map((object) => {
    return {
      machine: object.machine_description,
    };
  });

  console.log("dataset time data:", dataSetTimes);
  console.log("dataset machine data:", dataSetMachines);
  // console.log("dataset: ", dataSetTimes);

  // console.log(dataSetTimes);
  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
    dataSets: {
      byCalendarDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      }),
      byProductionDateTime: structuredClone(dataSet).map((hour) => {
        return hour;
      })
    },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat("en-US", {
      year: "2-digit" /*** To check: in chart-form-controls-mixin.js, it is  "2-digit" ***/,
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    startingDateTime: new Date(formData.p_param.p_start_datetime),
    endingDateTime: new Date(formData.p_param.p_end_datetime),
  };

  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /***
   * Step 1 Calculate Avg values
   *         return new HourlyMachineEfficiency
   */

  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   *
   */
  console.log(dates.startingDateTime);
  console.log(dates.endingDateTime);
  let timeDiff =
    dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, "") -
    dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, "");
  console.log("timeDiff", timeDiff);

  const startingDateTimeFmt = dates.dateTimeFormat
    .format(dates.startingDateTime)
    .replace(/,/g, "");
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat
    .format(dates.endingDateTime)
    .replace(/,/g, "");
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2.a Calculate chartTitles
   *
   */
  const chartTitles = {
    subTitles: {
      loadedOnDateTime: `@${dates.dateTimeFormat
        .format(new Date())
        .replace(/,/g, "")}`,
      timeSpan: `${dates.dateTimeFormat
        .format(dates.startingDateTime)
        .replace(/,/g, "")} - ${dates.dateTimeFormat
        .format(dates.endingDateTime)
        .replace(/,/g, "")}`,
    },
    title: `${formData.p_param.p_process_description} ${formData.p_param.p_line_number} Line Wait Time`,
  };

  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix =
    formData.p_param.p_process_description +
    "-Pareto-Machine-Stops-" +
    startingDateTimeFmt +
    "|" +
    endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);

  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   *
   */

  function setExpMenus(root) {
    // set Export Menu
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top",
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      dataSource: dataSetTimes,
      dataFields: {
        machine: "Machine",
        labelText: "Time Category",
        value: "Wait Time (min)",
      },
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      csvOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      pdfOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
    });

    expPlugin.get("menu").get("items").shift();

    expPlugin.events.on("workbookready", function (event) {
      const wsName = event.workbook.SheetNames[0];
      const wsRange = event.workbook.Sheets[wsName]["!ref"];
      const lastRowNumber = parseInt(
        wsRange.split(":")[1].match(/\d+$/)[0],
        10
      );

      console.log("wsName", wsName);
      console.log("wsRange", wsRange);
      console.log("lastRowNumber", lastRowNumber);
      event.workbook.Sheets[wsName]["!cols"] = getAppropriateWidthForColumns(
        event.workbook.Sheets[wsName]
      );

      for (var row = 1; row < lastRowNumber; row++) {
        let cell =
          event.workbook.Sheets[wsName][
            sheetJs.utils.encode_cell({ c: 2, r: row })
          ];
        console.log("cell 0", cell);
        cell.z = "#,##0";
      }

      function getAppropriateWidthForColumns(worksheet) {
        const wsRange = worksheet["!ref"];
        const lastColumnNumber = sheetJs.utils.decode_col(
          wsRange.split(":")[1].match(/[a-zA-Z]+/g)[0]
        );
        const lastRowNumber = parseInt(
          wsRange.split(":")[1].match(/\d+$/)[0],
          10
        );
        let maxColumnLengths = [];

        for (let j = 0; j <= lastColumnNumber; j++) {
          const maxCellLengthForWholeColumn = Array.from(
            { length: lastRowNumber },
            (_, i) => i
          ).reduce((acc, i) => {
            const cell = worksheet[`${sheetJs.utils.encode_col(j)}${i + 1}`];

            // empty cell
            if (!cell) {
              return acc;
            }

            const charLength = cell.v.toString().length + 1;

            return acc > charLength ? acc : charLength;
          }, 0);

          maxColumnLengths.push({ wch: maxCellLengthForWholeColumn });
        }

        return maxColumnLengths;
      }
    });
  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      text: chartTitles.title,
      x: am5.p50,
    });
    // chart.children.unshift(mainTitle);

    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 2,
      }),
      paddingBottom: -5,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0,
    });

    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50,
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100,
    });

    subTitle.children.push(timespanLabel);
    subTitle.children.push(fetchedOnLabel);

    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
  }

  function reloadRanges(scrollBarsYaxisY, colors, yAxes1) {
    if (dataSetMachines.length === 0) {
      return;
    }

    // Removes ranges from the chart
    yAxisRanges.forEach((range) => {
      yAxes1.axisRanges.removeValue(range);
    });
    scrollBarsYAxisRanges.forEach((range) => {
      scrollBarsYaxisY.axisRanges.removeValue(range);
    });

    yAxisRanges = scrollBarsYAxisRanges = [];

    dataSetMachines.forEach((machine, index) => {
      const rangeLabelWidth = 170;
      const categoryColor = colors.getIndex(index);

      let categoryDataItem = yAxes1.makeDataItem({});
      let axisRange = yAxes1.createAxisRange(categoryDataItem);
      yAxisRanges.push(axisRange);

      let firstTimeWithCategory =
        dataSetTimes[
          dataSetTimes.findIndex((time) => time.machine === machine.machine)
        ];
      let lastTimeWithCategory =
        dataSetTimes[
          dataSetTimes.findLastIndex((time) => time.machine === machine.machine)
        ];
      console.log("first Time category :", firstTimeWithCategory);
      categoryDataItem.set("category", firstTimeWithCategory.category);
      categoryDataItem.set("endCategory", lastTimeWithCategory.category);

      categoryDataItem.get("label").setAll({
        dx: rangeLabelWidth * -1,
        fill: categoryColor,
        fontWeight: "600",
        location: 0,
        maxWidth: 170,
        oversizedBehavior: "wrap",
        text: machine.machine,
        textAlign: "start",
      });
      categoryDataItem.get("tick").setAll({
        length: rangeLabelWidth,
        location: 0,
        stroke: categoryColor,
        strokeDasharray: [5],
        strokeOpacity: 1,
        strokeWidth: 2,
        visible: true,
      });
      categoryDataItem.get("grid").setAll({
        stroke: categoryColor,
        strokeDasharray: [5],
        strokeOpacity: 1,
        strokeWidth: 2,
      });

      let scrollBarYDataItem = scrollBarsYaxisY.makeDataItem({});
      let scrollBarYAxisRange =
        scrollBarsYaxisY.createAxisRange(scrollBarYDataItem);
      scrollBarsYAxisRanges.push(scrollBarYAxisRange);

      scrollBarYDataItem.set("category", firstTimeWithCategory.category);
      scrollBarYDataItem.set("endCategory", lastTimeWithCategory.category);

      scrollBarYDataItem.get("tick").setAll({
        stroke: categoryColor,
        strokeOpacity: 1,
        strokeWidth: 3,
        location: 0,
        visible: true,
        length: scrollBarsYaxisY.width(),
      });
    });
  }

  useLayoutEffect(() => {
    //createChart() logics in vue are implemented here.
    let root = am5.Root.new(chartId);
    root.utc = false;

    root.setThemes([am5themes_Animated.new(root)]);

    // Chart Creation
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 15,
        paddingTop: 0,
        panX: false,
        panY: true,
        // pinchZoomX: true,
        wheelX: "none",
        wheelY: "panY",
      })
    );

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04,
    });

    chart.zoomOutButton.set("forceHidden", true);

    // set export menu options for the chart.
    setExpMenus(root);

    setTitles(root, chart);
    let colors = chart.get("colors");
    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#,##0",
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0,
      maxPosition: 0.95,
    });
    xAxis.children.push(
      am5.Label.new(root, {
        fontWeight: "500",
        text: `Time (min)`,
        textAlign: "center",
        x: am5.p50,
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      minGridDistance: 25,
    });

    yRenderer.labels.template.setAll({
      lineHeight: 0.75,
      maxWidth: 170,
      oversizedBehavior: "wrap",
      text: "{labelText}",
      textAlign: "right",
      width: 170,
    });
    yRenderer.labels.template.adapters.add("text", function (text, target) {
      return target.dataItem && target.dataItem.dataContext
        ? target.dataItem.dataContext.labelText
        : text;
    });
    // Create Y-axis 1 for Machine Description
    let yAxes1 = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        // max: this.yAxisMaxTime + 50,
        max: 100,
        min: 0,
        minZoomCount: 5,
        // maxZoomCount: this.chartingComponents.scrollBars.maxZoom,
        maxZoomCount: 10,

        renderer: yRenderer,
        strictMinMax: true,

        tooltip: am5.Tooltip.new(root, { forceHidden: true }),
        tooltipLocation: 0.5,
      })
    );
    yAxes1.children.unshift(
      am5.Label.new(root, {
        fontWeight: "500",
        rotation: -90,
        text: "Time Categories",
        textAlign: "center",
        y: am5.p50,
      })
    );

    // yAxes1.events.on("datavalidated", function (ev) {
    //   // no need to shrink the scrollbar
    //   if (
    //     self.chartingComponents.dataSets.times.length <
    //     self.chartingComponents.scrollBars.maxZoom
    //   ) {
    //     self.chartingComponents.yAxis.zoom(0, 1);
    //     return;
    //   }

    //   // shrink the scrollbar to fit the maxZoom
    //   self.chartingComponents.yAxis.zoom(
    //     0,
    //     self.chartingComponents.scrollBars.maxZoom /
    //       self.chartingComponents.dataSets.times.length
    //   );
    // });

    yAxes1.get("renderer").grid.template.set("forceHidden", true);

    //series 1 On Time (over RunTime yAxis)
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        legendLabelText: "[{stroke}]{name}[/]",
        sequencedInterpolation: true,
        yAxis: yAxes1,
        categoryYField: "category",
        xAxis: xAxis,
        valueXField: "value",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "left",
          labelText: "{valueX}",
        }),
        tooltipLocation: 0.5,
      })
    );

    series1.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
    });
    // vue code 418 - 424 (mahedran need to validate)

    series1.columns.template.adapters.add("fill", (fill, target) => {
      return colors.getIndex(series1.columns.indexOf(target));
    });
    series1.columns.template.adapters.add("stroke", (fill, target) => {
      return colors.getIndex(series1.columns.indexOf(target));
    });
    // (this below is a vue code equivalent, need to review it later)
    // series1.columns.template.adapters.add("fill", (fill, target) => {
    //   return colors.getIndex(
    //     dataSetMachines.indexOf(target.dataItem.dataContext.machine)
    //   );
    // });

    // series1.columns.template.adapters.add("stroke", (stroke, target) => {
    //   return colors.getIndex(
    //     dataSetMachines.indexOf(target.dataItem.dataContext.machine)
    //   );
    // });

    let scrollBarsY = chart.set(
      "scrollbarY",
      am5xy.XYChartScrollbar.new(root, {
        orientation: "vertical",
        width: 100,
      })
    );

    let scrollBarsYaxisX = scrollBarsY.chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );
    let scrollBarsYaxisY = scrollBarsY.chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
        }),
      })
    );
    scrollBarsYaxisY
      .get("renderer")
      .labels.template.adapters.add("text", function (text, target) {
        return target.dataItem && target.dataItem.dataContext
          ? target.dataItem.dataContext.labelText
          : text;
      });
    let ySeries2 = scrollBarsY.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        categoryYField: "category",
        valueXField: "value",
        xAxis: scrollBarsYaxisX,
        yAxis: scrollBarsYaxisY,
      })
    );
    // ySeries2.set("fill", am5.color); // ** Set fill color for the columns here **
    // ySeries2.set("stroke", am5.color("#00FF00"));

    // ySeries2.columns.template.adapters.add("fill", (fill, target) => {
    //   return colors.getIndex(ySeries2.columns.indexOf(target));
    // });
    // ySeries2.columns.template.adapters.add("stroke", (fill, target) => {
    //   return colors.getIndex(ySeries2.columns.indexOf(target));
    // });

    ySeries2.columns.template.adapters.add("fill", (fill, target) => {
      return colors.getIndex(ySeries2.columns.indexOf(target));
    });
    ySeries2.columns.template.adapters.add("stroke", (fill, target) => {
      return colors.getIndex(ySeries2.columns.indexOf(target));
    });

    reloadRanges(scrollBarsYaxisY, colors, yAxes1);

    // DataProcessor steps may not be needed in React JS; - Velmani
    let dataProcessor = am5.DataProcessor.new(root, {
      numericFields: ["value"],
    });
    dataProcessor.processMany(dataSetTimes);

    let chartCursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        yAxis: yAxes1,
      })
    );
    chartCursor.lineX.set("visible", false);

    // vue code 458 (Mahendra need to do)

    let scrollBarsX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollBarsX);

    yAxes1.data.setAll(dataSetTimes);
    series1.data.setAll(dataSetTimes);
    scrollBarsYaxisY.data.setAll(dataSetTimes);
    ySeries2.data.setAll(dataSetTimes);

    series1.appear(1000);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return <div id={props.id} style={{ width: "100%", height: "600px" }}></div>;
}

export default ShowLineWaitTimeChart;
